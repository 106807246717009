.HexagonBG  {
    height: 70px;
    width: 75vw;
    max-width: 500px;
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
    border-top: 3px solid #1e90ff;
    border-bottom: 3px solid #1e90ff;
    position: relative;
    text-align: center;
    color: #FFF;
    background-color: #1e90ff;
    line-height: 70px;
    font-family: sans-serif;
}

#LandingMainPage{
  margin: auto;
}

.HexagonBG:before, .HexagonBG:after {
    content:'';
    position: absolute;
    top: 13px;
    height: 38px;
    width: 38px;
    border: 4px solid #1e90ff;
    background-color: #1e90ff;
    -webkit-transform: scale(0.8,1.25) rotate(45deg);
    -moz-transform: scale(0.8,1.25) rotate(45deg);
    -ms-transform: scale(0.8,1.25) rotate(45deg);
    transform: scale(0.8,1.25) rotate(45deg);
}
.HexagonBG:before {
    left: -20px;
    border-top: 0px solid transparent;
    border-right: 0px solid transparent;
}
.HexagonBG:after {
    right: -20px;
    border-bottom: 0px solid transparent;
    border-left: 0px solid transparent;
}

#MainPage {
    width: 80%; 
    margin: 0 auto; 
  }

.formula-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;

}

.formula {

  margin: 2px 0; /* Adjust spacing to match your design */
}

.divider {
  width: 100%;
  border-bottom: 3px solid mediumblue; /* Adjust the color and thickness to match your design */
}

.divider-number {

  margin: 2px 0; /* Adjust spacing to match your design */
}


.landing-box{
    margin: 0 auto;
    max-width: 500px;
}

.landing-img-size{
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  .HexagonBG {
      width: 75vw; /* Adjust the width to fit smaller screens */
      font-size: smaller; /* Optional: Adjust the font size if needed */
      height: 55px; /* Adjust the height if necessary */
      line-height: 60px; /* Adjust line-height to match the new height */
  }

  .HexagonBG:before, .HexagonBG:after {
      top: 10px; /* Adjust position */
      height: 30px; /* Adjust size */
      width: 30px; /* Adjust size */
  }

  .HexagonBG:before {
      left: -15px; /* Adjust position */
  }

  .HexagonBG:after {
      right: -15px; /* Adjust position */
  }

  .phone-size{
    width: 50%;
    height: 50%;
  }
  .landing-img-size{
    max-width: 100%;
  }

  .landing-box{
    margin: 0 auto;
    max-width: 500px;
  }

}
