body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Microsoft JhengHei','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: pre-line;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: gray !important;
}

.MuiInputLabel-root.Mui-focused {
  color: gray !important;
}

.non-p{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.backdrop {
  position: fixed;
  top: 10%; /* Adjust this as needed to ensure the top of the box is visible */
  left: 50%;
  width: 50vw;
  max-height: 80vh; /* Adjusted for better visibility */
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-start; /* Align content to the start */
  align-items: flex-start; /* Align items to the start */
  z-index: 1000;
  overflow: auto; /* Enables scrolling */
  animation: fadeIn 1s ease-in-out forwards;
}

.fullWidthContainer {
    width: 100%;
}

.underlined-text {
  text-decoration: underline;
}

.m-auto{  
  margin: 0,auto;
}

.underline {
  text-decoration: underline;
}

.color-gray{
  color: gray;
}

.color-coral{
  color: coral;
}

.color-darkgray{
  color: #606060;
}

.color-green{
  color: green;
}

.radius-10{
  border-radius:20px;
}

.radius-5{
  border-radius:5px;
}

.radius-top{
  border-radius: 10px 10px 0 0;
}

.radius-bottom{
  border-radius: 0 0 10px 10px;
}

.border-bold-green :hover{
  border: 2px solid green !important;
}

.border-bold-white:hover{
  border: 2px solid white !important;
}

.color-white{
  color: white;
}

.color-write-hover:hover{
  color: white;
}

.color-red{
  color: red;
}

.color-lightblue{
  color: dodgerblue;
}

.color-dodgerblue{
  color: dodgerblue;
}

.color-blue{
  color: mediumblue;
}

.color-green{
  color: green;
}

.color-midnightblue{
  color: midnightblue;
}

.backcolor-green{
  background-color: green;
}

.backcolor-black{
  background-color: black;
}

.backcolor-black-hover :hover{
  background-color: black ;
}

.backcolor-red{
  background-color: red;
}

.backcolor-blue{
  background-color: blue;
}

.backcolor-gray{
  background-color: #F0F0F0;
}

.backcolor-gray-hover :hover{
  background-color: gray ;
}

.backcolor-CCE5FF{
  background-color: #CCE5FF;
}

.backcolor-41b8a2{
  background-color: #41b8a2 !important;
  border-color: #41b8a2 !important;
}

.backcolor-8fc240{
  background-color: #8fc240;
}
.backcolor-3ab54a{
  background-color: #3ab54a;
}
.backcolor-135b56{
  background-color: #135b56;
}

.backcolor-FF9999{
  background-color: #FF9999;
}

.backcolor-FFFF33{
  background-color: #FFFF33;
}

.cpe-2{
 padding-right: 10px !important;
}

.cps-2{
  padding-left: 10px !important;
}

.img-size{
  width: 125px;
}

.half-opacity {
  opacity: 0.5;
}

.cursor-pointer{
  cursor: pointer;
}

.next-line-if-lf {
  white-space: pre-line;
}

.hr-color-black {
  border-top: 1px solid rgb(54, 1, 75) !important;
}

.small-phone-txt{
  font-size: 20px ;
}

.font-size-t4{
  font-size: 20px ;
}

.font-size-20{
  font-size: 20px ;
}

.font-size-24{
  font-size: 24px ;
}

.font-size-t7{
  font-size: 14px ;
}

.font-size-t8{
  font-size: 20px ;
}

.font-size-105{
  font-size: 105% ;
}

.font-size-t9{
  font-size: 16px;
}

.visibility-hidden{
  visibility:hidden;
}

.test-height{
  height: 1px !important;
}

.desktop-image {
  display: block;
}

.mobile-image {
  display: none;
}

.img-fluid-shy {
  max-width: 100%; /* This makes the image responsive */
  height: auto; /* This maintains the aspect ratio */
  max-height: 200px; /* This limits the height of the image */ 
}

.text-align-left{
  text-align: left;
}

@media screen and (max-width: 600px) {
  .small-phone-txt{
    font-size: 9.5px !important;
  }

  .cps-2{
    padding-left: 0 !important;
  }

  .cpe-2{
    padding-right: 0 !important;
   }



  .font-size-t4{
    font-size: 12px !important;
  }

  .font-size-t8{
    font-size: 10px ;
  }

  .font-size-t7{
    font-size: 10px !important;
  }

  .font-size-t9{
    font-size: 10px !important;
  }

  .font-size-24{
    font-size: 12px ;
  }

  h1,h2,h3,h4,h5{
    font-size: 14px !important;
  }

  .desktop-image {
      display: none;
  }

  .mobile-image {
      display: block;
  }

  .backdrop {
    width: 90vw;
    height: 90vh;
  }

}
